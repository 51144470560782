import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Select from "react-select";

class EditOrganisationUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorgloading: false,
      storeData: {
        currencyCode: "", 
        rate: "", 
        councilsProductsId: "",
        isAppleDefault: false
      },
      ratesList: [],
      storeList: [],
      flashMessage: null,
      flashType: null,
    };

    this.onStoreChange = this.onStoreChange.bind(this);
    this.onStoreSubmit = this.onStoreSubmit.bind(this);
  }

  componentDidMount() {
    //this.loadStore();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.councilsProductsId !== prevProps.councilsProductsId) {
      this.getStoresByCouncilProductId();
    }
  }

  onStoreChange(e) {
    const state = this.state.storeData;
    state[e.target.name] = e.target.value;
    this.setState({ storeData: state });
  }

  getStoresByCouncilProductId = () => {
    let id = this.props.match.params.id;
    let councilsProductsId = this.props.councilsProductsId;
    this.setState({ editorgloading: true });
    axios
      .get(
        `${API_CONSTANTS.COUNCIL.GET_COUNCIL_PRODUCT_STORE}/${councilsProductsId}`
      )
      .then((res) => {
        this.setState({ editorgloading: false });
        this.setState({ storeList: res.data });
      })
      .catch((err) => {
        this.setState({ editorgloading: false });
        console.log("error storeList is : " + err);
      });
  };

  resetStore = (callback) => {
    this.setState(
      {
        storeData: {
          currencyCode: "", 
          rate: "", 
          councilsProductsId: "",
          isAppleDefault: false
        },
      },
      () => callback && callback()
    );
  };

  onStoreSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ editorgloading: true });

    let storeData = this.state.storeData;
    let councilsProductsId = this.props.councilsProductsId;

    let data = {
      currencyCode: storeData.currencyCode,
      rate: storeData.rate,
      isAppleDefault: storeData.isAppleDefault,
      councilsProductsId: this.props.councilsProductsId,
    };
    if (storeData.id) {
      // EDIT
      axios
        .put(
          `${API_CONSTANTS.COUNCIL.EDIT_COUNCIL_PRODUCT_STORE}/${storeData.id}`,
          data
        )
        .then((res) => {

          this.setState({
            flashMessage: "Council Product Store updated successfully !!",
            flashType: "success",
          });
          // Hide the flash message after 3 seconds
          setTimeout(() => {
            this.setState({ flashMessage: null, flashType: null });
          }, 2000);
          //this.resetStore();
          this.setState({ editorgloading: true });
          this.getStoresByCouncilProductId();
        })
        .catch((err) => {
          this.setState({ editorgloading: false });
          console.log("error is : " + err);
          this.setState({
            flashMessage: "Something went wrong !!",
            flashType: "success",
          });
          // Hide the flash message after 3 seconds
          setTimeout(() => {
            this.setState({ flashMessage: null, flashType: null });
          }, 2000);
        });
    } else {

      // NEW
      axios
        .post(`${API_CONSTANTS.COUNCIL.CREATE_COUNCIL_PRODUCT_STORE}`, data)
        .then((res) => {
          this.setState({
            flashMessage: "Council Product Store updated successfully !!",
            flashType: "success",
          });
          // Hide the flash message after 3 seconds
          setTimeout(() => {
            this.setState({ flashMessage: null, flashType: null });
          }, 2000);

          //this.resetStore();
          this.setState({ editorgloading: false });
          this.getStoresByCouncilProductId();
          //this.editStore(null, null, res.data);
        })
        .catch((err) => {
          this.setState({ editorgloading: false });
          console.log("error is : " + err);
          this.setState({
            flashMessage: "Something went wrong !!",
            flashType: "success",
          });
          // Hide the flash message after 3 seconds
          setTimeout(() => {
            this.setState({ flashMessage: null, flashType: null });
          }, 2000);
        });
    }
  }

  handleRatesCheckboxClick = (e) => {
    const state = this.state.storeData;
    state[e.target.name] = !state[e.target.name];
    this.setState({ storeData: state });
  };

  handleAppleCheckboxClick = (e) => {
    const state = this.state.storeData;
    state[e.target.name] = !state[e.target.name];
    this.setState({ storeData: state }); 
  }


  editStore = (e, storeId, storeObj = null) => {
    this.resetStore(() => {
      let store =
        storeObj || this.state.storeList.find((store) => store.id == storeId);
      if (store && store.id) {
        let data = {
          id: store.id,
          currencyCode: store.currencyCode,
          rate: store.rate,
          isAppleDefault: store.isAppleDefault,
          councilsProductsId: this.props.councilsProductsId,
        };

        this.setState({
          storeData: data,
        });
      }
    });
  };

  
  deleteCouncilProductStore = (e, storeId) => {
    axios
      .delete(`${API_CONSTANTS.COUNCIL.DELETE_COUNCIL_PRODUCT_STORE(storeId)}`)
      .then((res) => {
        this.setState({
            flashMessage: "Council Product Store deleted successfully !!",
            flashType: "success",
          });
          // Hide the flash message after 3 seconds
          setTimeout(() => {
            this.setState({ flashMessage: null, flashType: null });
          }, 2000);

        this.setState({ loading: false });
        this.getStoresByCouncilProductId();
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("error is : " + err);
        this.setState({
          flashMessage: "Something went wrong !!",
          flashType: "",
        });

        setTimeout(() => {
          this.setState({ flashMessage: null, flashType: null });
        }, 2000);
      });
  };

  addNewStore = () => {
    this.resetStore();
  };


  render() {
    const {
      storeData,
      storeList,
    } = this.state;

    return (
      <>

        {/* Flash Message */}
        {this.state.flashMessage && (
          <div
            className={`custom-flash-message ${this.state.flashType}`}
            style={{
              position: "fixed",
              zIndex: 1060,
              top: "30px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: this.state.flashType === "success" ? "#28a745" : "#dc3545",
              color: "#fff",
              padding: "15px 20px",
              borderRadius: "4px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            {this.state.flashMessage}
          </div>
        )}


        <div
          class="modal fade new__modal rate-currenices-modal"
          id="addStoreModal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-xl our-custom-modal" role="document">
            <div class="modal-content">
              <div class="custom-modal-header">
                <div class="modal-header">
                  <h5 class="modal-title">Add Store</h5>
                  <button
                    onClick={() => this.resetStore()}
                    type="button"
                    class="close closed__btn"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="icon-cross"></span>
                  </button>
                </div>
              </div>
              <div class="modal-body">
                <div className={`show-advertisement pd-t-20 mg-t-20`}>
                  <h5 class="mg-b-20 text-center">
                    <strong>Store Details</strong>
                  </h5>
                  <form onSubmit={this.onStoreSubmit}>
                    <ul class="list-advertisement list-adds">
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">Currency Code</label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter currency code"
                              value={this.state.storeData.currencyCode}
                              name="currencyCode"
                              onChange={this.onStoreChange}
                              required
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">Rate</label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Enter rate"
                              value={this.state.storeData.rate}
                              name="rate"
                              onChange={this.onStoreChange}
                              required
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                            &nbsp;
                          </div>
                          <div class="col-sm-6">
                            <label class="custom-label-text">
                              <input
                                type="checkbox"
                                checked={this.state.storeData.isAppleDefault}
                                name="isAppleDefault"
                                onChange={this.handleAppleCheckboxClick}
                              />
                              Is Apple Default
                            </label>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="text-center">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            onClick={this.addNewStore}
                          >
                            Add New
                          </button>
                          <span>&nbsp;</span>
                          <button type="submit" class="btn btn-secondary">
                            Submit
                          </button>
                        </div>
                      </li>
                    </ul>
                  </form>

                  <div class="table-box">
                    <div class="table-responsive">
                      <table class="table" style={{ minWidth: "900px" }}>
                        <thead>
                          <tr>
                            <th style={{ width: "30%" }}>Currency Code</th>
                            <th style={{ width: "30%" }}>Rate</th>
                            <th style={{ width: "20%" }}>Is Apple Default</th>
                            <th class="text-center" style={{ width: "10%" }}>
                              Edit
                            </th>
                            <th class="text-center" style={{ width: "10%" }}>
                              Delete
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.storeList.map((store, index) => (
                            <tr key={index}>
                              <td>{store.currencyCode}</td>
                              <td>{store.rate}</td>
                              <td>{store.isAppleDefault.toString()}</td>
                              <td class="text-center">
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-primary text-edit"
                                  onClick={(e) => this.editStore(e, store.id)}
                                >
                                  <span class="icon-edit"></span>
                                </a>
                              </td>
                              <td class="text-center">
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-primary text-edit"
                                  onClick={(e) =>
                                    this.deleteCouncilProductStore(e, store.id)
                                  }
                                >
                                  <span class="icon-cross"></span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

EditOrganisationUser.propTypes = {
  auth: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
  // packages: PropTypes.array.isRequired,
  councilsProductsId: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(EditOrganisationUser));