const API_BASE_URL_A = process.env.REACT_APP_API_BASE_URL_A;

const API_BASE_URL_B = process.env.REACT_APP_API_BASE_URL_B;

const API_BASE_URL_C = process.env.REACT_APP_API_BASE_URL_C;

export const API_CONSTANTS = {
  AUTH: {
    LOGIN: `${API_BASE_URL_A}/p/admin/firebase/authenticate/token`,
  },
  COUNCIL: {
    REGISTER: `${API_BASE_URL_A}/api/v1/wcea/councils/register`,
    GET_ALL_COUNTRIES: `${API_BASE_URL_A}/api/v1/countries`,
    GET_ALL_COUNCILS: `${API_BASE_URL_A}/api/v1/wcea/councils`,
    GET_ALL_COUNCILS_USERS: `${API_BASE_URL_A}/api/v1/wcea/councils/users`,

    GET_COUNCIL_RATES_BY_ID: `${API_BASE_URL_A}/api/v1/wcea/council-product/council`,
    GET_PREMIUM_PACKAGE_RATES_BY_ID: `${API_BASE_URL_A}/api/v1/wcea/council-product/council`,
    CREATE_COUNCIL_RATES: `${API_BASE_URL_A}/api/v1/wcea/council-product`,
    EDIT_COUNCIL_RATES: `${API_BASE_URL_A}/api/v1/wcea/council-product`,
    CREATE_COUNCIL_PRODUCT_GOOGLE: `${API_BASE_URL_C}/product/google/create`,
    UPDATE_COUNCIL_PRODUCT_GOOGLE: `${API_BASE_URL_C}/product/google/update`,
    CREATE_COUNCIL_PRODUCT_AAPLE: `${API_BASE_URL_C}/product/apple/create`,
    DELETE_COUNCIL_RATES: (id) =>
      `${API_BASE_URL_A}/api/v1/wcea/remove-council-product/${id}`,

    GET_COUNCIL_GATEWAYS_BY_ID: `${API_BASE_URL_A}/api/v1/wcea/council-gateway/council`,
    CREATE_COUNCIL_GATEWAYS: `${API_BASE_URL_A}/api/v1/wcea/council-gateway`,
    EDIT_COUNCIL_GATEWAYS: `${API_BASE_URL_A}/api/v1/wcea/council-gateway`,
    DELETE_COUNCIL_GATEWAYS: (gatewayId) =>
      `${API_BASE_URL_A}/api/v1/wcea/remove-council-gateway/${gatewayId}`,

    // premium packages
    GET_PREMIUM_PACKAGES: `${API_BASE_URL_A}/api/v1/wcea/premium-package/council`,
    CREATE_PREMIUM_PACKAGE: `${API_BASE_URL_A}/api/v1/wcea/premium-package`,
    EDIT_PREMIUM_PACKAGE: `${API_BASE_URL_A}/api/v1/wcea/premium-package`,
    DELETE_PREMIUM_PACKAGE: (packageId) =>
      `${API_BASE_URL_A}/api/v1/wcea/remove-premium-package/${packageId}`,


    // Council Product Store
    GET_COUNCIL_PRODUCT_STORE: `${API_BASE_URL_A}/api/v1/wcea/council-product-store/council-product`,
    CREATE_COUNCIL_PRODUCT_STORE: `${API_BASE_URL_A}/api/v1/wcea/council-product-store`,
    EDIT_COUNCIL_PRODUCT_STORE: `${API_BASE_URL_A}/api/v1/wcea/council-product-store`,
    DELETE_COUNCIL_PRODUCT_STORE: (storeId) =>
      `${API_BASE_URL_A}/api/v1/wcea/council-product-store/${storeId}`,

    GET_COUNCILS_BY_ID: `${API_BASE_URL_A}/api/v1/wcea/councils`,
    UPDATE_COUNCILS_BY_ID: `${API_BASE_URL_A}/api/v1/wcea/councils`,
    GET_GROUPS: `${API_BASE_URL_A}/api/v1/wcea/councils`,
    ADD_GROUPS: `${API_BASE_URL_A}/api/v1/wcea/councils`,
    ASSIGN_LICENCE: `${API_BASE_URL_A}/api/v1/wcea/councils`,
    UPLOAD_CSV: `${API_BASE_URL_A}/api/v1/upload`,
    UPLOADS_MULTIPLE: `${API_BASE_URL_A}/api/v1/uploads/multiple-files`,
    BATCH_PROCESS: `${API_BASE_URL_A}/api/v1/batch/councils/contacts/reconciliation/uploads`,
    PUBLISHER_ZONES: `${API_BASE_URL_A}/api/v1/wcea/publisher-zones`,
    ADD_PUBLISHER_ZONES: `${API_BASE_URL_A}/api/v1/wcea/publisher-zones`,
    GET_PUBLISHER_ZONE: (id) =>
      `${API_BASE_URL_A}/api/v1/wcea/publisher-zones/${id}`,
    UPDATE_PUBLISHER_ZONE: (id) =>
      `${API_BASE_URL_A}/api/v1/wcea/publisher-zones/${id}`,

    GET_CHARGES: `${API_BASE_URL_A}/api/v1/wcea/charge`,
    ADD_CHARGE: `${API_BASE_URL_A}/api/v1/wcea/charge`,
    EDIT_CHARGE: (id) => `${API_BASE_URL_A}/api/v1/wcea/charge/${id}`,

    DELETE_PRODUCT_CHARGE: (productId, chargeId) =>
      `${API_BASE_URL_A}/api/v1/wcea/remove-council-product-charge/product/${productId}/charge/${chargeId}`,

    GET_VERIFICATION_FORMS: `${API_BASE_URL_A}/api/v1/wcea/verification-form`,
    DELETE_VERIFICATION_FORMS: (id) =>
      `${API_BASE_URL_A}/api/v1/wcea/remove-verification-form/${id}`,
    GET_VERIFICATION_FORM_FIELDS: `${API_BASE_URL_A}/api/v1/wcea/verification-form-field`,
    POST_VERIFICATION_FORM_FIELDS: `${API_BASE_URL_A}/api/v1/wcea/verification-form-field`,
    UPDATE_VERIFICATION_FORM_FIELDS: (id) =>
      `${API_BASE_URL_A}/api/v1/wcea/verification-form-field/${id}`,
    DELETE_VERIFICATION_FORM_FIELDS: (id) =>
      `${API_BASE_URL_A}/api/v1/wcea/remove-verification-form-field/${id}`,
    POST_VERIFICATION_FORM: `${API_BASE_URL_A}/api/v1/wcea/verification-form`,
    UPDATE_VERIFICATION_FORM: (id) =>
      `${API_BASE_URL_A}/api/v1/wcea/verification-form/${id}`,
    GET_LEAD_FORM_FIELDS: `${API_BASE_URL_A}/api/v1/wcea/lead-fields`,
  },
  ARTICLE: {
    PLATFORM_QUERY_SEARCH: `${API_BASE_URL_B}/getarticles`,
    PLATFORM_DOI_SEARCH: `${API_BASE_URL_B}/getarticlejson`,
    PLATFORM_ARTICLE_INCLUSION: `${API_BASE_URL_B}/articleinclusion`,
    PLATFORM_ARTICLE_REJECTION: `${API_BASE_URL_B}/articlerejection`,
    ALL_INCLUSION_ARTICLE_BY_PLATFORM: `${API_BASE_URL_B}/allarticlesbyplatform`,
    PLATFORM_ALL_INCLUSION_ARTICLE: `${API_BASE_URL_B}/allplatformarticles`,
    PLATFORM_SYSTEM_ASSETS: `${API_BASE_URL_B}/systemassets`,
    PLATFORM_CORE_ARTICLE_GENERATE: `${API_BASE_URL_B}/generatearticle`,
    UPLOAD: `${API_BASE_URL_B}/upload`,
  },
};

export const API_KEY = {
  WCEA_API: process.env.REACT_APP_WCEA_API_KEY,
  WCEA_SECRET: process.env.REACT_APP_WCEA_SECRET_KEY
};
